import { AxiosRequestConfig } from 'axios';
import BaseService from './base-service';
import { UserContact } from '../../entities/user.entity';
import { CustomerPortal } from '../../entities/commerce/customer-portal.entity';
import { RequestBodyType } from '../../types/request-body-type';
import { MediaItem } from '../../entities/media-item.entity';
import { MyUser } from '../../types/my-user';

export type UpdateMeRequestBody = RequestBodyType<
  Pick<MyUser, 'preferredName' | 'jobTitle' | 'preferences'>
>;

export interface UpdateEmailRequestBody {
  email: string;
  sendVerificationEmail: boolean;
}

class MeService extends BaseService {
  getMe(): Promise<MyUser> {
    return this._apiClient.get('/api/rest/v1/me').then(response => response.data);
  }

  updateMe(data: UpdateMeRequestBody): Promise<MyUser> {
    return this._apiClient.patch('/api/rest/v1/me', data).then(response => response.data);
  }

  getCustomerPortal(): Promise<CustomerPortal> {
    return this._apiClient.get('/api/rest/v1/me/customer-portal').then(response => response.data);
  }

  resetMyContactInfo(): Promise<any> {
    return this._apiClient
      .post('/api/rest/v1/me/reset-contact-info')
      .then(response => response.data);
  }

  /**
   * Updates the phone number and send the verification code if needed
   * @param phoneNumber
   */
  setPhoneNumber(phoneNumber: string): Promise<UserContact> {
    return this._apiClient
      .put('/api/rest/v1/me/phone-number', { phoneNumber })
      .then(response => response.data);
  }

  verifyPhoneNumber(code: string): Promise<UserContact> {
    return this._apiClient
      .post('/api/rest/v1/me/phone-number/verify', { code })
      .then(response => response.data);
  }

  /**
   * Updates the email and sends the verification email if needed
   * @param {Object} data - Request body sent to Ayd API
   * @param {string} data.email - New email to set
   * @param {boolean} data.sendVerificationEmail - Whether to send out the verification email
   */
  setEmail(data: UpdateEmailRequestBody): Promise<UserContact> {
    return this._apiClient.put('/api/rest/v1/me/email', data).then(response => response.data);
  }

  uploadProfilePicture(file: Blob, onUploadProgress?: (val: number) => void): Promise<MediaItem> {
    const formData = new FormData();
    formData.append('file', file);

    const params: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (onUploadProgress) {
      params.onUploadProgress = ({ loaded, total }) => {
        onUploadProgress(total ? Math.round((100 * loaded) / total) : 0);
      };
    }

    return this._apiClient
      .post('/api/rest/v1/me/profile-picture', formData, params)
      .then(response => response.data);
  }
}

export default MeService;
